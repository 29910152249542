<!--数据分析课程列表-->
<template>
  <div class="dataAnalysisList">
    <el-form :inline="true" class="form-inline" @submit.native.prevent>
      <!-- <el-form-item>
        <el-select v-model="post.live_mode">
          <el-option v-for="item in courseCateGory1" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin-right:">
        <el-select v-model="post.live_status">
          <el-option v-for="item in courseCateGory2" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-input class="searchInput" v-model.trim="post.name" :placeholder="'输入章节名称搜索'"></el-input>
      </el-form-item>
    </el-form>
    <!--分页-->
    <pagination2 :option="post" url="/course/analysisList" @complete="loading = false">
      <template scope="{ tableData }">
        <el-table
          :data="tableData"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333'
          }"
        >
          <el-table-column label="章节名称" prop="name"></el-table-column>
          <el-table-column label="定时观看">
            <template slot-scope="scope">
              <div v-if="scope.row.startTime > 0">{{ scope.row.startTime | formatTimeStamp('yyyy-MM-dd hh:mm') }}</div>
              <div v-else>- -</div>
            </template>
          </el-table-column>
          <el-table-column label="上课老师" prop="teacher_name"></el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <div class="btns">
                <router-link :to="`EditCourse/dataAnalysisDetail?sr_id=${scope.row.sr_id}&type=${$route.query.course_type}`" class="toDetail"
                  >统计详情</router-link
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
    <loading v-show="loading" leftPos="0"></loading>
  </div>
</template>
<script>
export default {
  name: 'dataAnalysisList3',

  data() {
    return {
      loading: true,
      post: {
        live_mode: 1,
        live_status: 1,
        course_type: this.$route.query.course_type,
        course_id: this.$route.query.course_id,
        name: ''
      },
      courseCateGory1: [
        {
          name: '全部类型',
          id: 1
        },
        {
          name: '普通模式',
          id: 2
        },
        {
          name: '极速模式',
          id: 3
        }
      ],
      courseCateGory2: [
        {
          name: '全部状态',
          id: 1
        },
        {
          name: '已结束',
          id: 2
        },
        {
          name: '未开始',
          id: 3
        },
        {
          name: '直播中',
          id: 4
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.dataAnalysisList {
  padding-right: 20px;
}
.toDetail {
  font-size: 14px;
  color: $color;
  line-height: 19px;
  text-decoration: none;
}
::v-deep .form-inline {
  font-size: 14px;
  text-align: right;
  .el-input--suffix {
    width: 120px;
    margin-right: 20px;
  }
  .searchInput {
    width: 150px;
  }
  .el-form-item {
    margin-right: 0;
  }
}
</style>
